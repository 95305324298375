import gql from 'graphql-tag'

export const GATEWAY_FEATURES_FRAGMENT = `
emailAuditing,
referrals,
referralPortal,
googleAuth,
suretyBonds
`
export const REQUIRED_DOCUMENTS_QUERY_SHARED_FRAGMENT = `
requiredDocuments(order_by: { dateCreated: DESC }) {
createdByAdminUserId
        dateCreated
        draftId
        id
        requiredDocumentFlow
        overallStatus
        requiredDocumentType
        requiredDocumentMessages(order_by: { dateCreated: DESC }) {
          attachmentId
          createdByUserId
          canAuthorizedUserAcceptOrReject
          createdByUser {
            familyName
            givenName
          }
          dateCreated
          fromParty
          hasApproval
          id
          parentMessageId
          isAutomatedMessage
          message
          requiredDocumentId
          attachment {
            id
            draftId
            infectedFileStatus
            fileName
            received
          }
        }
      }
`
export const DOCUMENT_REPORTS_QUERY = gql`
  query DocumentReports(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order: [ReportSortInput!]
    $where: ReportFilterInput
  ) {
    documentReports: documentReports(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        id
        supplierId
        displayName
        variant
        status
        filters
        criteria
        createdBy {
          firstName
          lastName
        }
        createdOn
      }
    }
  }
`
export const DOCUMENT_REPORT_DOWNLOAD_URL = gql`
  query DocumentReportDownloadUrl($reportId: ID!) {
    documentReportDownloadUrl: documentReportDownloadUrl(reportId: $reportId)
  }
`

const DRAFT_BG_SWITCH_FRAGMENT = `
bGSwitch {
  affirmationIrrevocability
  beneficiaryApproval
  currentBankFullName
  currentExpiryDate
  currentGuaranteeNumber
  currentSecurityType
  currentValue
  id
  indemnityAgreement
  irrevocableOrder
  isCashInTermDeposit
  organisationId
  securityInterestDeclaration
  status
  termDepositEndDate
  transactionUnwindingClause
}
`

const IDENTIFICATION_SHARED_FRAGMENT = `identification {
  adminStatus
  clientStatus
  companyDetailsACN
  companyDetailsIsListed
  companyDetailsIsRegulated
  companyDetailsIsSubsidiaryListed
  companyDetailsIsNormalType
  companyDetailsHasBeneficialOwners
  companyDetailsListedExchangeName
  companyDetailsName
  companyDetailsNatureOfBusiness
  companyDetailsPrincipalPlaceOfBusiness
  companyDetailsRegisteredAddress
  companyDetailsRegulatorLicenseNumber
  companyDetailsRegulatorName
  companyDetailsSubsidiaryListedCompanyName
  companyDetailsSubsidiaryListedExchangeName
  companyDetailsTaxFatcaStatus
  companyDetailsTaxFatcaStatusOther
  companyDetailsOnlyOperatesInAustralia
  companyDetailsTaxForeignResidentCountry
  companyDetailsTaxGIIN
  companyDetailsTaxHasBeneficialOwnerForeignResident
  companyDetailsTaxHasGIIN
  companyDetailsTaxIsForeignResident
  hasDividendsIncome
  companyDetailsTaxType
  dataType
  entityType
  hasMacAccount
  id
  macAccountName
  macAccountNumber
  postalAddress
  taxExemptionDetails
  taxExemptionType
  taxStatus
  userId
  organisationId
  organisation{
    relationshipManager{
      firstName
      lastName
      emailAddress
    }
  }
  individuals {
    emailAddress
    firstName
    middleNames
    greenIdResult{
      greenIdVerificationStatus
      dateOfBirth
      email
      firstName
      lastName
      middleName
      address{
        country
        number
        postalCode
        state
        street
        suburb
        unit
      }
    }
    greenIdToken
    greenIdVerificationId
    greenIdVerificationStatus
    id
    identificationId
    isAccountSignatory
    isForeignResident
    isApprover
    isBeneficialOwner
    isDirectorOrSecretary
    isMacVerified
    isValidated
    macVerifiedNumber
    phoneMobile
    surname
    title
    userRole
    simpleKycDataIndividualId
    simpleKycDataIndividualRecord {
      address{
        country
        country_name
        number
        postalCode
        state
        street
        suburb
        type
        unformattedAddress
        unit
      }
      dateOfBirth
      firstName
      middleNames
      id
      isBeneficialOwner
      isDirector
      lastName
      roles
    }
  }
}
`

const HISTORY_SHARED_FRAGMENT = `
nodes {
  changes {
    label
    field
    newValue
    newDisplayValue
    oldValue
    oldDisplayValue
  }
  changeSetId
  changeType
  id
  objectType
  recordName
  triggeredAt
  triggeredBy {
    familyName
    givenName
    isBankAuthor
  }
}
totalCount
pageInfo {
  endCursor
  hasNextPage
  hasPreviousPage
  startCursor
}`

export const DRAFTS_FOR_BANK_PORTAL = gql`
  query DraftsForBankPortal(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order_by: BankDraftSort
    $where: BankDraftFilter
  ) {
    drafts: draftsForBankPortal(
      after: $after
      before: $before
      first: $first
      last: $last
      order_by: $order_by
      where: $where
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        status
        agreementContent
        amount
        purpose
        id
        processType
        megAppNo
        megAppId
        issuers: parties(where: { partyVariant: ISSUER }) {
          name
        }
        acceptedChannelPartnerId
        submittedAt
        createdAt
        isVisibleToSupplier
        feeSummary {
          securityTypeCash
          securityTypeProperty
          supplier {
            name
          }
        }
      }
    }
  }
`

export const DRAFT_QUERY = gql`
  query DraftById($where: BankDraftFilter!) {
    draft: draftsForBankPortal(where: $where) {
      nodes{
        processType
        agreementContent
        currentPeriodEnd
        additionalSupplierDetail {
          fundingAccountBsb
          fundingAccountNumber
          hasFundingAccountAuthorisation
        }
        amount
        status
        capabilities
        feeSummaryId
        organisationId
        feeSummary{
          biannualFeeCoefficient
          establishmentFee
          feeSummaryStatus
          name
          recurringFeePercentage
          supplier{
            name
          }
        }
        relationshipManager{
          firstName
          lastName
          emailAddress
        }
        creator{
          email
        }
        deliveryDetails {
          address
          recipientGivenName
          recipientFamilyName
          companyName
          phoneNumber
      }
      isVisibleToSupplier
      directorEmailAddress
      directorFirstName
      directorLastName
      estimatedFees
      expiresAt
      expectedReturnDate
      documentId
      parties {
        abn
        address
        id
        name
        notes
        partyVariant
        partyEntityType
        primaryContact{
          givenName
          familyName
          phoneNumber
          email
        }
      }
      paymentDetails {
        bankAccountBsb
        bankAccountName
        bankAccountNumber
        establishmentFee
        paymentDescription
        requestedAt
        securityAmount
      }
      referralPortalDeal{
        entityName
        memberFullName
      }
      invoices{
        createdAt
        depositReferenceNumber
        hostedInvoiceAttachmentId
        hostedReceiptAttachmentId
        canGenerateHostedInvoice
        stripeInvoiceHostedUrl
        stripeInvoicePdf
        stripeInvoiceStatus
        stripeReceiptUrl
        invoiceLineItems{
          amount
          description
          }
      }
      stripeSubscriptionStatus
      stripeSubscriptionCanceledAt
      stripeSubscriptionId
      rejectionReason
      issuerDetailsOfSourceOfFunds
      invoiceRecipientEmailAddresses
      issuerSourceOfFunds
      purpose
      agreementContent
      projectAddress
      referenceNumber
      specifiedOtherPurpose
      contract {
        bankFrontedBondsRequested
        totalValue
        startDate
        practicalCompletionDate
        finalCompletionDate
        hasPreviousContracts
        hasSimilarContracts
        isSubcontractor
        hasDesignObligationsLiability
        defectsAndFaults {
          active
          detail
        }
        issuesAndDisputes {
          active
          detail
        }
        projectNotOnBudget
        replacingExistingSecurity {
          active
          detail
        }
        hasDelayEvents
        isExistingProject
        defectsLiabilityPeriodExtended{
          active
          detail
        }
        bankFrontedBondsRequestedReason
      }
      id
      requestedSuretyDocuments {
        id
        purpose
        amount
        periodStartDate
        periodEndDate
        paymentEstablishmentFee
        paymentAnnualCost
        documentWording
      }
      deliveryDetails{
        recipientGivenName
        recipientFamilyName
        address
      }
      acceptedChannelPartnerId
      ${IDENTIFICATION_SHARED_FRAGMENT}
      attachments {
        id
        variant
        contentType
        fileName
        received
        draftRequestedDocumentId
        infectedFileStatus
      }
      issuerSourceOfFunds
      issuerDetailsOfSourceOfFunds
      facility {
        id
        name
        facilityApplicationId
        documentSetUpCost
        documentRecurringPercentageCost
        bankFrontedBondsSetUpCost
        bankFrontedBondsRecurringPercentageCost
        premiumDocumentRecurringPercentageCost
        premiumBankFrontedBondsRecurringPercentageCost
      }
      totalFees
      refundAccountName
      refundAccountNumber
      refundBSB
      megAppNo
      megAppId
      megDetails
      ${DRAFT_BG_SWITCH_FRAGMENT}
    }
  }
}
`

export const DRAFT_BANK_PROCESS_QUERY = gql`
  query DraftById($where: BankDraftFilter!) {
    draft: draftsForBankPortal(where: $where) {
      nodes {
        attachments {
          id
          variant
          contentType
          fileName
          received
          draftRequestedDocumentId
          infectedFileStatus
        }
        purpose
        megAppNo
        megAppId
        megDetails
      }
    }
  }
`

export const DRAFT_HISTORY_QUERY = gql`
  query DraftHistory(
    $where: BankDraftFilter!
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
  ) {
    draft: draftsForBankPortal(where: $where) {
      nodes {
        id
        draftHistory(after: $after, before: $before, first: $first, last: $last, order_by: { triggeredAt: DESC }) {
          ${HISTORY_SHARED_FRAGMENT}
        }
      }
    }
  }
`

export const IDENTIFICATION_HISTORY_QUERY = gql`
  query IdentificationHistory(
    $where: BankIdentificationFilter!
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
  ) {
    identification: identificationsForBankPortal(where: $where) {
      nodes {
        id
        identificationHistory(after: $after, before: $before, first: $first, last: $last, order_by: { triggeredAt: DESC }) {
          ${HISTORY_SHARED_FRAGMENT}
        }
      }
    }
  }
`

export const DOCUMENT_HISTORY_QUERY = gql`
  query DocumentHistory(
    $where: BankDocumentFilter!
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
  ) {
    document: documentsForBankPortal(where: $where) {
      nodes {
        id
        history(after: $after, before: $before, first: $first, last: $last, order_by: { triggeredAt: DESC }) {
          ${HISTORY_SHARED_FRAGMENT}
        }
      }
    }
  }
`

export const DRAFT_ATTACHMENT_DOWNLOAD = gql`
  query DraftAttachmentDownload($draftId: ID!, $id: ID!) {
    draftAttachmentDownloadForBankPortal(draftId: $draftId, id: $id) {
      downloadPresignedUrl
    }
  }
`

export const DOCUMENTS_FOR_BANK_PORTAL = gql`
  query DocumentsForBankPortal(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order_by: BankDocumentSort
    $where: BankDocumentFilter
  ) {
    documents: documentsForBankPortal(
      after: $after
      before: $before
      first: $first
      last: $last
      order_by: $order_by
      where: $where
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        agreementContent
        amount
        status
        id
        issuers: parties(where: { partyVariant: ISSUER }) {
          name
        }
        establishedAt
        documentVariant
        supplierName
      }
    }
  }
`

export const DOCUMENT_QUERY = gql`
  query DocumentById($where: BankDocumentFilter) {
    document: documentsForBankPortal(where: $where) {
      nodes {
        agreementContent
        amount
        documentVariant
        originalDraftId
        expiresAt
        expectedReturnDate
        parties {
          abn
          address
          name
          notes
          partyVariant
        }
        invoices {
          createdAt
          depositReferenceNumber
          hostedInvoiceAttachmentId
          canGenerateHostedInvoice
          stripeInvoiceHostedUrl
          stripeInvoicePdf
          stripeInvoiceStatus
          stripeReceiptUrl
          invoiceLineItems {
            amount
            description
          }
        }
        history(first: 25, order_by: { triggeredAt: DESC }) {
          ${HISTORY_SHARED_FRAGMENT}
        }
        annualCost
        establishmentFee
        status
        establishedAt
        capabilities
        guaranteeNumber
        id
        purpose
        specifiedOtherPurpose
      }
    }
  }
`

export const FACILITY_APPLICATIONS_FOR_BANK_PORTAL = gql`
  query FacilityApplications(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order_by: FacilityApplicationSort
    $where: FacilityApplicationFilter
  ) {
    facilityApplicationsForBankPortal(
      after: $after
      before: $before
      first: $first
      last: $last
      order_by: $order_by
      where: $where
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        id
        createdAt
        lastUpdatedAt
        status
        facilityType
        organisation {
          name
        }
        applicant {
          companyName
          contactName
          emailAddress
          phoneNumber
        }
        submission {
          submittedAt
        }
      }
    }
  }
`
export const FACILITIES_FOR_BANK_PORTAL = gql`
  query FacilitiesForBankPortal(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order_by: DocumentFacilitySort
    $where: DocumentFacilityFilter
  ) {
    facilitiesForBankPortal(
      after: $after
      before: $before
      first: $first
      last: $last
      order_by: $order_by
      where: $where
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        id
        name
        documentVariant
        provider
        totalEstablished
        limit
        startDate
        endDate
      }
    }
  }
`

export const FACILITY_BY_ID_FOR_BANK_PORTAL = gql`
  query FacilitiesForBankPortal($where: DocumentFacilityFilter) {
    facilitiesForBankPortal(where: $where) {
      nodes {
        id
        name
        documentVariant
        provider
        totalEstablished
        totalProjected
        limit
        startDate
        endDate
        setUpCost
        annualCost
        documentSetUpCost
        documentRecurringFeeType
        documentRecurringCostFrequency
        documentRecurringMinimumAnnualCost
        documentAdjustedCost
        isAssuroFacility
        sharedFacilityWithOrganisations {
          id
          organisationId
          createdAt
          sharedWithOrganisation {
            name
          }
        }
        organisationId
        organisation {
          name
        }
      }
    }
  }
`
export const FACILITY_APPLICATIONS_FOR_BANK_PORTAL_COUNT = gql`
  query FacilityApplications($where: FacilityApplicationFilter) {
    facilityApplicationsForBankPortal(where: $where) {
      totalCount
    }
  }
`

export const FACILITY_APPLICATION_BY_ID_FOR_BANK_PORTAL = gql`
  query FacilityApplicationById($where: FacilityApplicationFilter!) {
    facilityApplicationsForBankPortal(where: $where) {
      nodes {
        id
        createdAt
        status
        applicant {
          abn
          address
          companyName
          contactName
          contactTitle
          emailAddress
          entityType
          industry
          phoneNumber
          role
          website
        }
        applicationInformation {
          facilityLimit
        }
        facilityType
        supplierId
        submission {
          submittedAt
        }
        attachments {
          id
          fileName
          variant
          downloadPresignedUrl
          received
          infectedFileStatus
        }
        totalFees
        hasBankApproval
        isInvoicePaid
        isVisibleToSupplier
        invoiceNumber
        capabilities
        entities {
          abn
          name
        }
        establishmentRecord {
          facilityName
          limit
          startDate
          endDate
          setUpCost
          annualCost
          documentSetUpCost
          documentRecurringPercentageCost
          bankFrontedBondsSetUpCost
          bankFrontedBondsRecurringPercentageCost
          premiumDocumentRecurringPercentageCost
          premiumBankFrontedBondsRecurringPercentageCost
        }
        sharedFacilityWithOrganisations {
          id
          organisationId
          createdAt
          sharedWithOrganisation {
            name
          }
        }
        organisationId
        organisation {
          name
        }
        properties {
          address
          estimatedValue
          id
          isMortgaged
          isPrimaryPlaceOfResidence
          lender
          outstandingMortgage
          propertyType
          owners {
            applicantRelationship
            id
            name
          }
        }
      }
    }
  }
`

export const FINANCIAL_SERVICES_GUIDE_HTML_STRING = gql`
  query FSGHtmlString {
    fSGHtmlString
  }
`

export const GENERATE_AGENCY_AGREEMENT_HTML_STRING_FOR_DRAFT_FOR_BANK_PORTAL = gql`
  query GatewayFeaturesenerateAgencyAgreementHtmlStringForDraftForBankPortal($draftId: ID!) {
    generateAgencyAgreementHtmlStringForDraftForBankPortal(draftId: $draftId)
  }
`

export const GET_BANK_ASSETS_LIST = gql`
  query ListBankAssets {
    listBankAssets {
      name
      assetType
    }
  }
`

export const GET_USER_LIST = gql`
  query GetUserList(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order: [UserSortInput!]
    $where: UserFilterInput
  ) {
    getUsersForBankPortal(after: $after, before: $before, first: $first, last: $last, order: $order, where: $where) {
      nodes {
        id
        familyName
        givenName
        email
        createdAt
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`

export const GET_SUPPLIER_LIST = gql`
  query GetSupplierList($where: SupplierFilter) {
    getSuppliersForBankPortal(where: $where) {
      id
      name
      createdAt
      createdBy
      notificationAddress
      gvpId
      feeSummaries {
        name
        createdAt
        establishmentFee
        recurringFeePercentage
        id
        feeSummaryStatus
        assuroAgencyAgreementTemplateName
        productPreviewTemplateName
        name
        details
        minValue
        maxValue
        supplierId
        biannualFeeCoefficient
        productType
        securityTypeCash
        securityTypeProperty
      }
    }
  }
`

export const GET_ADMIN_LIST = gql`
  query CognitoGetBankUsers(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order: [CognitoUserSortInput!]
    $where: CognitoUserFilterInput
    $userRoles: [BankUserRole!]
  ) {
    cognitoGetBankUsers(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
      userRoles: $userRoles
    ) {
      nodes {
        id
        status
        isEnabled
        userName
        emailAddress
        createdOn
        firstName
        lastName
        lastUpdatedAt
        phoneNumber
        isSmsMfaRequired
        supplierId
        userRoles
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`

export const GET_IDENTIFICATION_KYC_FLAG_LIST = gql`
  query GetSimpleKycDataFlagsByIdentificationId(
    $after: String
    $before: String
    $identificationId: String!
    $first: PaginationAmount
    $last: PaginationAmount
    $order_by: IFlagsDataSort
    $where: IFlagsDataFilter
  ) {
    getSimpleKycDataFlagsByIdentificationId(
      after: $after
      before: $before
      identificationId: $identificationId
      first: $first
      last: $last
      order_by: $order_by
      where: $where
    ) {
      nodes {
        bo
        descriptor
        flagGrade
        msg
        nodeName
        relevance
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`

export const GET_IDENTIFICATION_LIST = gql`
  query GetIdentificationList(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order_by: BankIdentificationSort
    $where: BankIdentificationFilter
  ) {
    identificationsForBankPortal(
      after: $after
      before: $before
      first: $first
      last: $last
      order_by: $order_by
      where: $where
    ) {
      nodes {
        id
        companyDetailsName
        companyDetailsACN
        entityType
        clientStatus
        adminStatus
        createdAt
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`
export const GET_IDENTIFICATION_BY_ID = gql`
  query GetIdentificationById($where: BankIdentificationFilter) {
    identificationsForBankPortal(where: $where) {
      nodes {
        id
        simpleKycDataId
        simpleKycDataRecord {
          simpleKycApplicationId
          applicationStatus
          businessNames
          companyABN
          entityClassification
          legalBusinessName
          principalPlaceOfBusinessAddress
          registeredOfficeAddress
          createdAt
        }
        dataType
        entityType
        hasMacAccount
        macAccountName
        macAccountNumber
        clientStatus
        adminStatus
        comments
        companyDetailsName
        companyDetailsACN
        companyDetailsNatureOfBusiness
        companyDetailsPrincipalPlaceOfBusiness
        companyDetailsRegisteredAddress
        companyDetailsIsRegulated
        companyDetailsRegulatorName
        companyDetailsRegulatorLicenseNumber
        companyDetailsIsListed
        companyDetailsListedExchangeName
        companyDetailsIsSubsidiaryListed
        companyDetailsSubsidiaryListedCompanyName
        companyDetailsSubsidiaryListedExchangeName
        companyDetailsIsNormalType
        companyDetailsHasBeneficialOwners
        companyDetailsTaxType
        companyDetailsTaxIsForeignResident
        hasDividendsIncome
        companyDetailsTaxForeignResidentCountry
        companyDetailsTaxHasGIIN
        companyDetailsTaxGIIN
        companyDetailsTaxFatcaStatus
        companyDetailsTaxFatcaStatusOther
        companyDetailsOnlyOperatesInAustralia
        companyDetailsTaxHasBeneficialOwnerForeignResident
        taxStatus
        taxExemptionType
        taxExemptionDetails
        postalAddress
        organisation {
          name
        }
        organisationId
        createdAt
        userId
        identificationIndustry
        industryAdditionalDetails
        entityWealthAccumulation
        individuals {
          id
          title
          firstName
          middleNames
          surname
          isDirectorOrSecretary
          isApprover
          isValidated
          isBeneficialOwner
          isAccountSignatory
          isForeignResident
          taxForeignResidentCountry
          taxpayerIdentificationNumber
          taxReasonNoTIN
          taxReasonUnavailableTIN
          isMacVerified
          emailAddress
          macVerifiedNumber
          phoneMobile
          identificationId
          greenIdVerificationId
          userRole
          greenIdVerificationStatus
          greenIdResult {
            watchlistStatusCollection {
              watchlistType
              result
              resultLabel
              resultDescription
            }
          }
        }
      }
    }
  }
`

export const GET_IDENTIFICATION_BY_ID_FOR_REVIEW = gql`
  query GetIdentificationById($where: BankIdentificationFilter) {
    identificationsForBankPortal(where: $where) {
      nodes {
        id
        comments
        riskDetails
        riskStatus
        riskDetailsCreatedBy
        riskDetailsCreatedAt
        simpleKycDataId
        simpleKycDataRecord {
          simpleKycApplicationId
          applicationStatus
          businessNames
          companyABN
          entityClassification
          legalBusinessName
          principalPlaceOfBusinessAddress
          registeredOfficeAddress
          createdAt
          simpleKycDataIndividualRecord {
            id
            address {
              country
              country_name
              number
              postalCode
              state
              street
              suburb
              type
              unformattedAddress
              unit
            }
            dateOfBirth
            firstName
            middleNames
            lastName
            isDirector
            isBeneficialOwner
            roles
          }
        }
        companyDetailsPrincipalPlaceOfBusiness
        companyDetailsRegisteredAddress
        dataType
        entityType
        hasMacAccount
        macAccountName
        macAccountNumber
        clientStatus
        adminStatus
        companyDetailsName
        companyDetailsACN
        postalAddress
        organisationId
        createdAt
        individuals {
          id
          title
          firstName
          middleNames
          surname
          isDirectorOrSecretary
          isApprover
          isValidated
          isBeneficialOwner
          isAccountSignatory
          isForeignResident
          isMacVerified
          emailAddress
          macVerifiedNumber
          phoneMobile
          identificationId
          greenIdVerificationId
          userRole
          greenIdVerificationStatus
          simpleKycDataIndividualId
          greenIdResult {
            address {
              country
              number
              postalCode
              state
              street
              streetType
              suburb
              unit
            }
            watchlistStatusCollection {
              watchlistType
              resultLabel
              result
              resultDescription
            }
            greenIdVerificationStatus
            dateOfBirth
            email
            firstName
            middleName
            lastName
          }
        }
      }
    }
  }
`
export const GET_CHANNEL_PARTNER_LIST = gql`
  query getChannelPartnersForBankPortal(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order: [ChannelPartnerSortInput!]
    $where: ChannelPartnerFilterInput
  ) {
    channelPartnersForBankPortal(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      nodes {
        id
        name
        active
        establishmentFeeDiscountAmount
        discountPercentage
        referralPercentage
        apiKey
        createdAt
        isPublic
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`

export const GET_USER_BY_ID = gql`
  query GetUserById($where: UserFilterInput) {
    getUsersForBankPortal(where: $where) {
      nodes {
        familyName
        givenName
        email
        address
        industry
        phoneNumber
        createdAt
      }
    }
  }
`
export const GET_CHANNEL_PARTNER_BY_ID = gql`
  query channelPartnersForBankPortal($where: ChannelPartnerFilterInput) {
    channelPartnersForBankPortal(where: $where) {
      nodes {
        id
        name
        createdAt
        active
        establishmentFeeDiscountAmount
        discountPercentage
        referralPercentage
        webhookUrl
        apiKey
        contactEmail
        isPublic
      }
    }
  }
`
export const GET_ORGANISATION_LIST = gql`
  query GetOrganisationList(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order_by: OrganisationSort
    $where: OrganisationFilter
  ) {
    getOrganisationsForBankPortal(
      after: $after
      before: $before
      first: $first
      last: $last
      order_by: $order_by
      where: $where
    ) {
      nodes {
        id
        abn
        industryType
        relationshipManager {
          firstName
          lastName
          emailAddress
        }
        name
        organisationStructure
        createdAt
        discountPercentage
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`

export const GET_USER_ORGANISATION_LIST = gql`
  query GetUserOrganisationList(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order_by: OrganisationSort
    $where: OrganisationFilter
    $userId: ID!
  ) {
    searchOrganisationsForMembersForBankPortal(
      after: $after
      before: $before
      first: $first
      last: $last
      order_by: $order_by
      where: $where
      userId: $userId
    ) {
      nodes {
        id
        abn
        industryType
        name
        organisationStructure
        relationshipManager {
          firstName
          lastName
        }
        createdAt
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`

export const GET_ORGANISATION_BY_ID = gql`
  query GetOrganisationById($where: OrganisationFilter) {
    getOrganisationsForBankPortal(where: $where) {
      nodes {
        id
        abn
        address
        industryType
        postalAddress
        name
        createdAt
        discountPercentage
        postalAddressSameAsBusinessAddress
        leadSource
        isOngoingClient
        relationshipManager {
          firstName
          lastName
          emailAddress
        }
        relationshipManagerId
        organisationFeeSummaries {
          feeSummaryId
        }
        channelPartnerOrganisations {
          nodes {
            channelPartner {
              id
              name
            }
          }
        }
        referralPortalAssociation {
          id
          entityId
          referralPortalMember {
            name
            surname
            id
          }
        }
      }
    }
  }
`

export const GENERATE_CHANNEL_PARTNER_REPORT = gql`
  query GenerateChannelPartnerReport($channelPartnerId: ID!) {
    generateChannelPartnerReport(channelPartnerId: $channelPartnerId) {
      monthYear
      amount
      savings
      existingClients
      newClients
      numberOfDocuments
    }
  }
`

export const GENERATE_SUMMARY_CHART_REPORT = gql`
  query GenerateSummaryChartReport {
    generateSummaryChartReport {
      monthYear
      numberOfBankguaranteeClients
      numberOfSuretyBondsClients
      numberOfClients
      numberOfDocuments
      numberOfSuretyBondsDocuments
      numberOfBankguaranteeDocuments
      bankguaranteeAmount
      suretyBondsAmount
      totalAmount
    }
  }
`

export const GENERATE_FORECAST_CHART_REPORT = gql`
  query GenerateForecastChartReport {
    generateForecastChartReport {
      monthYear
      totalAmount
    }
  }
`
export const GENERATE_FORECAST_TOTAL_VALUE = gql`
  query LifetimeProjectionValue {
    lifetimeProjectionValue {
      lifetimeAmount
    }
  }
`
export const GENERATE_TOTAL_SURETY_BOND_FACILITIES_VALUE = gql`
  query totalValueOfSuretyFacilities {
    totalValueOfSuretyFacilities
  }
`
export const SURETY_BOND_SUMMARY = gql`
  query {
    totalSuretyBondsSummary {
      activeClientsNumber
      activeDocumentsNumber
      activeAmount
      totalClientsNumber
      totalDocumentsNumber
      totalAmount
    }
  }
`
export const BANK_GUARANTEE_SUMMARY = gql`
  query {
    totalBankGuaranteesSummary {
      activeClientsNumber
      activeDocumentsNumber
      activeAmount
      totalClientsNumber
      totalDocumentsNumber
      totalAmount
    }
  }
`

export const GET_ORGANISATION_MEMBERS = gql`
  query GetOrganisationMembers(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $where: OrganisationFilter
    $memberWhere: MemberFilter
  ) {
    getOrganisationsForBankPortal(after: $after, before: $before, first: $first, last: $last, where: $where) {
      nodes {
        name
        members(where: $memberWhere) {
          nodes {
            userId
            createdAt
            email
            familyName
            givenName
            role
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          totalCount
        }
      }
    }
  }
`

export const GET_FACILITY_PAYMENTS_FOR_BANK_PORTAL = gql`
  query GetFacilityPaymentsForBankPortal(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order_by: FacilityApplicationSort
    $where: FacilityApplicationFilter
  ) {
    facilityApplicationsForBankPortal(
      after: $after
      before: $before
      first: $first
      last: $last
      order_by: $order_by
      where: $where
    ) {
      nodes {
        id
        establishmentRecord {
          annualCost
          setUpCost
        }
        organisation {
          id
          name
        }
        status
        totalFees
        createdAt
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`
export const GET_INVOICES_FOR_BANK_PORTAL = gql`
  query GetInvoicesForBankPortal(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order_by: BankInvoiceSort
    $where: BankInvoiceFilter
  ) {
    invoicesForBankPortal(
      after: $after
      before: $before
      first: $first
      last: $last
      order_by: $order_by
      where: $where
    ) {
      nodes {
        id
        depositReferenceNumber
        stripeInvoiceStatus
        draftId
        createdAt
        total
        draft {
          id
          status
          amount
          agreementContent
          issuers: parties(where: { partyVariant: ISSUER }) {
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`

export const GET_DRAFT_PAYMENTS_FOR_BANK_PORTAL = gql`
  query GetDraftPaymentsForBankPortal(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order_by: BankDraftSort
    $where: BankDraftFilter
  ) {
    draftsForBankPortal(
      after: $after
      before: $before
      first: $first
      last: $last
      order_by: $order_by
      where: $where
    ) {
      nodes {
        id
        agreementContent
        amount
        status
        totalFees
        submittedAt
        issuers: parties(where: { partyVariant: ISSUER }) {
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`

export const IMPORT_JOBS_QUERY = gql`
  query ImportJobsForBankPortal(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order_by: ImportJobSort
    $where: ImportJobFilter
  ) {
    importJobsForBankPortal(
      after: $after
      before: $before
      first: $first
      last: $last
      where: $where
      order_by: $order_by
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        status
        recordsFailed
        recordsProcessed
        createdAt
      }
    }
  }
`

export const IMPORT_JOBS_DETAILS_QUERY = gql`
  query ImportJobsForBankPortal($where: ImportJobFilter) {
    importJobsForBankPortal(where: $where) {
      nodes {
        id
        status
        errors
        recordsFailed
        recordsProcessed
        files {
          downloadPresignedUrl
        }
        items {
          errors
          rowNumber
        }
      }
    }
  }
`

export const DRAFT_ORIGINATIONS_QUERY = gql`
  query DraftOriginationsForBankPortal(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order_by: ApiDraftOriginationSort
    $where: ApiDraftOriginationFilter
  ) {
    draftOriginationsForBankPortal(
      after: $after
      before: $before
      first: $first
      last: $last
      where: $where
      order_by: $order_by
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        amount
        draftOriginationStatus
        draftReferenceId
        purpose
        channelPartnerOrganisationName
        channelPartner {
          name
        }
        createdAt
      }
    }
  }
`

export const DRAFT_ORIGINATIONS_BY_ID_QUERY = gql`
  query DraftOriginationsForBankPortal($where: ApiDraftOriginationFilter) {
    draftOriginationsForBankPortal(where: $where) {
      nodes {
        agreementContent
        amount
        channelPartner {
          id
          name
        }
        channelPartnerOrganisationAbnOrAcn
        channelPartnerOrganisationBusinessAddress
        channelPartnerOrganisationReferenceId
        channelPartnerOrganisationIndustry
        channelPartnerOrganisationName
        channelPartnerOrganisationPostalAddress
        draftId
        draftOriginationStatus
        draftReferenceId
        draftType
        expectedReturnDate
        expiryDate
        parties {
          abnOrAcn
          address
          name
          partyEntityType
          representatives {
            email
            familyName
            givenName
            isOwner
          }
          type
        }
        organisationId
        createdAt
        purpose
        purposeSpecificOther
      }
    }
  }
`

export const WEBHOOK_RESULTS_QUERY = gql`
  query WebhookResultsForBankPortal($where: ApiWebhookResultFilterInput) {
    webhookResultsForBankPortal(where: $where) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        requestType
        responseCode
        requestData
        responseData
        createdAt
      }
    }
  }
`

export const GET_IDENTIFICATION_INDIVIDUAL_GEENID_TOKEN = gql`
  query initializeGreenIdBankPortal($identificationIndividualId: ID) {
    initializeGreenIdBankPortal(identificationIndividualId: $identificationIndividualId) {
      id
      simpleKycDataIndividualRecord {
        address {
          country
          country_name
          number
          postalCode
          state
          street
          type
          unformattedAddress
          unit
          suburb
        }
        dateOfBirth
      }
      firstName
      surname
      middleNames
      emailAddress
      greenIdVerificationId
      greenIdVerificationStatus
      greenIdToken
    }
  }
`
export const GET_RELATIONSHIP_MANAGER_DRAFTS = gql`
  query getRelationshipManagerDrafts(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order_by: BankDraftSort
    $where: BankDraftFilter
  ) {
    drafts: draftsForBankPortal(
      after: $after
      before: $before
      first: $first
      last: $last
      order_by: $order_by
      where: $where
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        id
        agreementContent
        expectedReturnDate
        isVisibleToSupplier
        megDetails
        megAppId
        megAppNo
        amount
        status
        acceptedChannelPartnerId
        submittedAt
        createdAt
        purpose
        identification {
          id
          adminStatus
          clientStatus
          organisation {
            id
            name
            relationshipManager {
              firstName
              lastName
            }
            leadSource
            industryType
            isOngoingClient
          }
        }
        feeSummary {
          securityTypeCash
          securityTypeProperty
          supplier {
            name
          }
        }
        issuers: parties(where: { partyVariant: ISSUER }) {
          name
        }
      }
    }
  }
`

export const GET_SIMPLE_KYC_DATA_URL = gql`
  query getSimpleKycDataUrlByIdentificationId($identificationId: String!, $urlName: KycUrlType!) {
    getSimpleKycDataUrlByIdentificationId(identificationId: $identificationId, urlName: $urlName)
  }
`
export const SUPPLIER_DRAFT_QUERY = gql`
  query DraftById($where: BankDraftFilter!) {
    draft: draftsForBankPortal(where: $where) {
      nodes {
        processType
        agreementContent
        amount
        status
        capabilities
        creator {
          email
        }
        deliveryDetails {
          address
          recipientGivenName
          recipientFamilyName
          companyName
          phoneNumber
        }
        relationshipManager {
          firstName
          lastName
          emailAddress
        }
        estimatedFees
        expiresAt
        expectedReturnDate
        feeSummaryId
        feeSummary {
          biannualFeeCoefficient
          establishmentFee
          feeSummaryStatus
          name
          recurringFeePercentage
          supplier {
            name
          }
        }
        documentId
        parties {
          abn
          address
          id
          name
          notes
          partyVariant
          partyEntityType
          primaryContact {
            givenName
            familyName
            phoneNumber
            email
          }
        }
        rejectionReason
        issuerDetailsOfSourceOfFunds
        invoiceRecipientEmailAddresses
        issuerSourceOfFunds
        isVisibleToSupplier
        purpose
        agreementContent
        projectAddress
        referenceNumber
        specifiedOtherPurpose
        id
        acceptedChannelPartnerId
        attachments {
          id
          variant
          contentType
          fileName
          received
          draftRequestedDocumentId
          infectedFileStatus
        }
        identification {
          id
          companyDetailsTaxType
          entityType
          entityWealthAccumulation
          companyDetailsNatureOfBusiness
          companyDetailsTaxHasGIIN
          companyDetailsTaxFatcaStatus
          companyDetailsTaxFatcaStatusOther
          companyDetailsTaxHasBeneficialOwnerForeignResident
          companyDetailsTaxIsForeignResident
          companyDetailsTaxForeignResidentCountry
          companyDetailsOnlyOperatesInAustralia
          taxExemptionDetails
          taxExemptionType
          taxStatus
          hasDividendsIncome
          postalAddress
          individuals {
            id
            title
            firstName
            surname
            middleNames
            phoneMobile
            userRole
            isDirectorOrSecretary
            isApprover
            isBeneficialOwner
            isAccountSignatory
            isForeignResident
            taxForeignResidentCountry
            taxpayerIdentificationNumber
            taxReasonNoTIN
            taxReasonUnavailableTIN
            simpleKycDataIndividualRecord {
              address {
                unformattedAddress
              }
              dateOfBirth
            }
            greenIdResult {
              address {
                country
                number
                postalCode
                state
                street
                streetType
                suburb
                unit
              }
              watchlistStatusCollection {
                watchlistType
                result
                resultLabel
                resultDescription
              }
              dateOfBirth
            }
          }
        }
        issuerSourceOfFunds
        issuerDetailsOfSourceOfFunds
        totalFees
        refundAccountName
        refundAccountNumber
        refundBSB
        ${DRAFT_BG_SWITCH_FRAGMENT}
      }
    }
  }
`

export const GET_FEES_LIST = gql`
  query GetFeeSummaries {
    getFeeSummariesForBankPortal {
      id
      biannualFeeCoefficient
      establishmentFee
      recurringFeePercentage
      name
      details
      feeSummaryStatus
      supplier {
        name
      }
    }
  }
`
export const GET_NOTIFICATIONS_LIST = gql`
  query GetNotificationsList(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order: [NotificationSortInput!]
    $where: NotificationFilterInput
  ) {
    getNotificationsForBankPortal(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      nodes {
        id
        userId
        metadata
        template
        sentOn
        subject
        event
        emailAddress
        createdOn
        externalId
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`
export const RELATIONSHIP_MANAGER_LIST = gql`
  query CognitoGetBankUsers(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order: [CognitoUserSortInput!]
    $where: CognitoUserFilterInput
    $userRoles: [BankUserRole!]
  ) {
    cognitoGetBankUsers(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
      userRoles: $userRoles
    ) {
      nodes {
        firstName
        lastName
        emailAddress
        id
        userRoles
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`

export const GET_FINANCE_RECORDS = gql`
  query financeRecordsForBankPortal(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order: [FinanceRecordSortInput!]
    $where: FinanceRecordFilterInput
  ) {
    financeRecordsForBankPortal(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        cursor
        node {
          amount
          dateTime
          financeType
          id
          organisationId
          performedBy
        }
      }
      nodes {
        amount
        dateTime
        financeType
        financier
        id
        organisationId
        performedBy
        performedByUser {
          emailAddress
          firstName
          lastName
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`

export const GET_STRIPE_SUBSCRIPTION_DETAILS = gql`
  query GetStripeSubscriptionDetails($draftId: ID!) {
    getStripeSubscriptionDetails(draftId: $draftId) {
      isLastInvoicePaid
      lastInvoiceDueDate
      subscriptionCancelledAt
      subscriptionEndedAt
    }
  }
`

export const SOURCE_OF_FUNDS_REF_DATA_FOR_BANK_PORTAL = gql`
  query sourceOfFundsReferenceData_ForBankPortal($draftId: ID!) {
    sourceOfFundsReferenceData_ForBankPortal(draftId: $draftId) {
      id
      key
      description
    }
  }
`

export const GATEWAY_FEATURES_QUERY = gql`
  query GatewayFeatures {
    features{
      ${GATEWAY_FEATURES_FRAGMENT}
    }
  }
`
export const GET_BG_APPLICATION_CONTACT_DETAILS = gql`
  query ApplicationContactDetailsForBankPortal($draftId: ID!) {
    applicationContactDetails: applicationContactDetailsForBankPortal(draftId: $draftId) {
      firstName
      lastName
      email
      mobilePhone
      businessPhone
      role
    }
  }
`

export const GET_DRAFT_REQUIRED_DOCUMENTS = gql`
  query DraftById($where: BankDraftFilter!) {
    draft: draftsForBankPortal(where: $where) {
      nodes{
        ${REQUIRED_DOCUMENTS_QUERY_SHARED_FRAGMENT}
      }
    }
  }
`
export const GET_ADMIN_USER = gql`
  query CognitoGetAuthenticatedUser {
    cognitoGetAuthenticatedUser {
      emailAddress
      firstName
      id
      lastName
      phoneNumber
      isSmsMfaRequired
    }
  }
`
export const GET_DEAL_LIST = gql`
  query ReferralPortalDeals(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order: [DealSortInput!]
    $where: DealFilterInput
  ) {
    referralPortalDeals(after: $after, before: $before, first: $first, last: $last, order: $order, where: $where) {
      nodes {
        id
        commissionAmount
        createdAt
        draftProduct
        draftAmount
        draftStatus
        draftDateSubmitted
        draftDateEstablished
        entityName
        memberFullName
        draftSupplier
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`

export const GET_REFERRAL_PORTAL_ENTITIES = gql`
  query ReferralPortalEntities(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order: [EntitySortInput!]
    $where: EntityFilterInput
  ) {
    referralPortalEntities(after: $after, before: $before, first: $first, last: $last, order: $order, where: $where) {
      nodes {
        id
        name
        parentEntityId
        entityType
        email
        phone
        addressLine1
        addressLine2
        addressSuburb
        addressState
        addressPostcode
        contactPerson
        abn
        bankingAccountNumber
        bankingBsbNumber
        relationshipManagerId
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`

export const GET_REFERRAL_PORTAL_MEMBERS = gql`
  query ReferralPortalMembers(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order: [ReferralPortalMemberSortInput!]
    $where: ReferralPortalMemberFilterInput
  ) {
    referralPortalMembers(after: $after, before: $before, first: $first, last: $last, order: $order, where: $where) {
      nodes {
        id
        cognitoUser_Username
        name
        entityId
        surname
        email
        mobilePhone
        position
        status
        role
        isSmsMfaRequired
        entity {
          name
          id
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`

export const GET_REFERRAL_PORTAL_MY_MEMBER = gql`
  query ReferralPortalMyMember {
    referralPortalMyMember {
      id
      cognitoUser_Username
      name
      surname
      email
      entityId
      mobilePhone
      referClientCode
      status
      position
    }
  }
`

export const GET_REFERRAL_ENTITY_BASE_BY_ID = gql`
  query ReferralPortalEntities($id: ID!) {
    referralPortalEntities(first: 1, where: { id: { eq: $id } }) {
      nodes {
        id
        name
        parentEntityId
        entityType
        email
        phone
        contactPerson
        abn
        relationshipManagerFirstName
        relationshipManagerLastName
        relationshipManagerEmail
        relationshipManagerPhone
      }
    }
  }
`
export const REFERRAL_PORTAL_COMMISSION_TIERS = gql`
  query {
    referralPortalCommissionTiers {
      commissionAmount
      minValue
    }
  }
`

export const ASSETS_LIST = gql`
  query {
    listBankAssets {
      name
      assetType
    }
  }
`

export const ASSET_CONTENT = gql`
  query BankAssetContent($name: String!, $bankAssetType: BankAssetType!) {
    bankAssetContent(name: $name, bankAssetType: $bankAssetType) {
      content
      contentType
    }
  }
`
export const FACILITY_APPLICATION_HISTORY_FOR_BANK_PORTAL = gql`
  query FacilityApplicationHistory($where: FacilityApplicationFilter!) {
    facilityApplicationsForBankPortal(where: $where) {
      nodes {
        facilityApplicationHistory(order_by: { triggeredAt: DESC }) {
          nodes {
            changeSetId
            changes {
              field
              label
              newDisplayValue
              newValue
              oldDisplayValue
              oldValue
            }
            changeType
            id
            recordName
            triggeredAt
            triggeredBy
            objectType
          }
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`
