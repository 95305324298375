import { ToastProgrammatic } from 'buefy'
import { GraphQLError } from 'graphql'

export const displayErrors = (errors: any) => {
  if (!errors.graphQLErrors) {
    ToastProgrammatic.open({ message: errors.message, type: 'is-danger' })
    return
  }
  errors.graphQLErrors.forEach((error: GraphQLError) => {
    ToastProgrammatic.open({ message: error.extensions?.message ?? error.message, type: 'is-danger', queue: false })
  })
}
